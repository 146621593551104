<template>
  <div class="page">
	<h2 style="text-align: center;" class="title">
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>未成年人隐私政策</font>
			</span>
		</b>
		<b></b>
	</h2>
	<p style="text-align: right;">
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>发布日期：</font>2022年9月19日
			</span>
		</b>
	</p>
	<p style="text-align: right;">
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>生效日期：</font>2022年9月19日
			</span>
		</b>
	</p>
	<p style="text-align: justify; margin-top: .5rem;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">{{appName}}</span>
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<font>（以下或称</font>“我们”）非常注重保护未成年人的个人信息及隐私，
      我们深知个人信息对未成年人（以下或称“你”）及其监护人（以下或称“您”）的重要性。为保障未成年人个人信息及隐私安全，并提供更加优质、多元的产品/服务，我们在
		</span>
		<b>
			<span style="color: rgb(51, 51, 51);">《</span>
		</b>
		<a :href="`https://jz-h5.yofish.com/information/privacy?appName=${appName}&appType=${appType}`" target="_self">
			<span style="color: rgb(24, 144, 255);">
				<font>{{appName}}</font>
			</span>
			<span style="color: rgb(24, 144, 255);">
				<font>隐私政策</font>
			</span>
		</a>
		<b>
			<span style="color: rgb(51, 51, 51);">》</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<font>的基础之上，专门制定了《未成年人隐私政策》。本政策旨在向您及您的孩子清晰地介绍在使用我们的产品</font>/服务时，
      我们如何收集、使用、存储和处理未成年人的个人信息，以及我们为您与您的孩子提供的访问、更正、删除和保护这些信息的方式
      <span style="color: rgb(51, 51, 51); font-size: 10.5pt;">。</span>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
				<br>
			</span>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">未成年人监护人特别声明</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>如您是未成年人的合法监护人，请您仔细阅读本政策并确认是否同意相关的未成年人个人信息处理规则以及是否同意您的孩子使用我们的产品</font>/服务。
      您应确认您的孩子在您的同意和指导下使用我们的产品/服务，并向我们提交个人信息。如果您不同意本政策的内容，
      请您要求您的孩子立即停止访问/使用我们的产品及服务，但这可能会导致您的孩子无法正常使用部分产品/服务，但不影响您的孩子使用我们提供的其他服务。
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">未成年人特别声明</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>如你是不满</font>18周岁的未成年人，请你确保在监护人的陪同和指导下阅读本政策，并在征得你的监护人同意后，使用我们的产品/服务或向我们提供信息。
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 10.5pt;">本政策是</span>
		<span style="font-size: 10.5pt;">{{appName}}</span>
		<span style="font-size: 10.5pt;">针对未成年用户个人信息保护的特别条款，适用于我们在中华人民共和国境内</span>
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">收集、使用、处理、或分享</span>
		<span style="font-size: 10.5pt;">未成年人个人信息等活动。本政策有特别规定的，以本政策为准；本政策未载明之处，请参照</span>
		<b>《</b>
		<a :href="`https://jz-h5.yofish.com/information/privacy?appName=${appName}&appType=${appType}`" target="_self">
			<span style="color: rgb(24, 144, 255);">
				<font>{{appName}}</font>
			</span>
			<span style="color: rgb(24, 144, 255);">
				<font>隐私政策</font>
			</span>
		</a>
		<b>》<b>
				<span style="color: rgb(51, 51, 51);">或您使用的特定服务的相应隐私政策</span>
			</b>
			<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">。
        如您对于本政策或与未成年人个人信息保护相关的事宜有任何问题，</span>
			<span style="font-size: 10.5pt;">
				<font>您可以通过本政策</font>“
			</span>
			<b>如何联系我们</b>
			<span style="font-size: 10.5pt;">”中提供的方式与我们联系，我们会尽快为您作出解答。</span>
		</b>
	</p>
	<p style="text-align: justify;">
		<b>
			<span style="font-size: 10.5pt;">
				<br>
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">本政策将帮助您了解以下内容：</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>一、</font>
				<font>我们如何识别未成年人的个人信息</font>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>二、</font>
				<font>我们如何收集和使用未成年人的个人信息</font>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>二、</font>
				<font>我们如何使用</font>Cookie和同类技术
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>四、</font>
				<font>我们如何共享、转让、公开披露未成年人的个人信息</font>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>五、</font>
				<font>我们如何保护和储存未成年人的个人信息</font>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>六、</font>
				<font>如何管理未成人的个人信息</font>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>七、</font>
				<font>通知和修订</font>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">八、如何联系我们</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">九、附录</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>【特别提示】请您和您的孩子在使用我们提供的各项产品</font>/服务前，仔细阅读并充分理解本《隐私政策》（重点内容我们已将字体加粗请您特别关注）
        并作出相应选择。一旦您主动提供监护人身份信息（如您的手机号码）并完成验证后，即意味着您同意我们按照本隐私政策处理您的孩子的相关信息。
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>我们将通过下述途径向您提供的产品</font>/服务：包括但不限于网站、App等客户端以及相关微信开放平台账号或小程序等。
      针对某些特定的产品/服务，我们还将制定单独的隐私政策，向您说明这些产品/服务的特殊政策。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<b>
				<span style="color: rgb(51, 51, 51);">
					<font>请您注意，本政策不适用于您或您的孩子通过我们的产品</font>/服务而接入的其他第三方产品/服务(“其他第三方”，
          包括您的交易相对方、任何第三方网站以及第三方服务提供者等)，具体规定请参照该第三方的隐私政策或类似声明，
				</span>
			</b>
			<b>
				<span style="color: rgb(49, 49, 49);">我们建议您或您的孩子在向第三方授权收集处理其任何个人信息之前征求您的同意。</span>
			</b>
			<br>
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<b>
				<span style="color: rgb(49, 49, 49);">
					<br>
				</span>
			</b>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">一、我们如何识别未成年人的个人信息</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">您应当理解，我们非常重视对未成年人个人信息的保护。</span>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>原则上，我们的网站和服务主要面向成人，未成年人用户必须在获取其监护人的授权同意后，才能继续使用我们的产品</font>/服务或向我们提供信息。
			</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">我们</span>
		<b>
			<span style="color: rgb(51, 51, 51);">仅能通过用户提交的信息（比如用户主动输入的年龄、生日等），合理地判断其是否为未成年人</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>；当我们合理判断其为未成年人时，我们会收集您的手机号码、电子邮箱等联系方式，并与您、取得联系，
        验证未成年人与您之间的监护关系，并确定是否已经获取您的授权同意。同时，按照法律法规的规定，</font>
        14周岁以下未成年人（儿童）的个人信息属于个人敏感信息。我们针对14周岁以下儿童个人信息的处理将按照个人敏感信息的有关法律规定和相关国家标准与要求进行。
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">二、我们如何收集和使用未成年人的个人信息</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>（</font>1）我们将严格按照有关法律法规的规定，遵循
		</span>
		<b>
			<span style="color: rgb(51, 51, 51);">合法使用、知情同意、正当必要、诚实信用、保障安全、目的限定</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">的原则收集和使用未成年人的个人信息，在未成年人注册成为</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">{{appName}}</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">用户时，需要至少提供手机号码以创建</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">{{appName}}</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">如您的孩子选择授权使用第三方账号登录时，
      我们会从第三方获取您的孩子共享的账号信息（如头像、昵称、地区、性别等信息）与其</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">{{appName}}</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">账号进行绑定用于快捷登录，我们会依据与第三方的约定，
      在符合相关法规的前提下，使用未成年人的个人信息。</span>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<font>（</font>2）
		</span>
		<span style="font-size: 10.5pt;">有关具体的使用目的、收集个人信息的范围以及拒绝提供相应信息的后果请您查阅</span>
		<b>
			<span style="color: rgb(51, 51, 51);">《隐私政策》</span>
		</b>
		<b>&nbsp;“</b>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>我们如何收集和使用您的个人信息</font>”
			</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">一节</span>
		<span style="font-size: 10.5pt;">进行详细了解</span>
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">。</span>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<span style="color: rgb(51, 51, 51); font-size: 12pt;">为向您和您的孩子提供更个性化、优质化的服务，
        您可以额外补充您的孩子的性别、生日、等信息。</span>
			<br>
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>（</font>3）我们仅会将收集的未成年人的信息用于本政策声明的目的和用途，
      如果我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您和您的孩子的授权同意。
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>（</font>4）征得同意的例外
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">1、为订立、履行个人作为一方当事人的合同所必需，
      或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">2、为履行法定职责或者法定义务所必需；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
      3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
      4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
      5、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">6、法律法规规定的其他情形。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">请注意，单独或与其他信息相结合无法识别未成年人的身份或者与其直接建立联系的信息，
      不属于个人信息。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>三、我们如何使用</font>Cookie和同类技术
			</span>
		</b>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<font>为确保网站正常高效运转、为您和您的孩子获得更轻松的访问体验、向您和您的孩子推荐可能感兴趣的内容，我们会在未成年人的设备终端</font>/系统上存储名
		</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>为</font>&nbsp;Cookie的小数据文件。除Cookie外，我们网页上常会包含一些电子图象(称为"单像素"GIF文件或"网络Beacon")等其他同类技术，
      它们可以帮助网站计算浏览网页的用户或访问某些Cookie。我们通过Cookie、Beacon等同类技
		</span>
		<span style="font-size: 12pt;">术收集、处理未成年人个人信息的相关规则，请您具体查阅《隐私政策》</span>
		<b>&nbsp;“我们如</b>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>何使用</font>Cookie和同类技术
			</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">”一节</span>
		<span style="font-size: 12pt;">进行详细了解。如我们需要超出本政策规定的范围收集和使用未成年人个人信息的，我们将再次征得</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">您和您的孩子</span>
		<span style="font-size: 12pt;">的同意。</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 12pt;">
			<br>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">四、我们如何共享、转让、公开披露未成年人的个人信息</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">具体的内容</span>
		<span style="font-size: 12pt;">请您查阅</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">《隐私政策》</span>
		<b>
			<span style="color: rgb(51, 51, 51);">“我们如何共享、转让、公开披露您的个人信息”</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">一节</span>
		<span style="font-size: 12pt;">进行详细了解</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">。针对未成年人，我们会严格按照未成年人个人信息相关的法律法规的规定采取措施，
      以确保仅出于合法正当、最小必要、明确的目的共享未成年人的个人信息：</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">（一）共享</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">请您知悉，</span>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>我们为您所提供的产品</font>/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息
			</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">。目前，除再次获得您明确同意或授权外，此类共享或委托处理涉及的主要情形包括：
      根据行政、司法等有权机关的要求提供必要信息；为提供账号一致性服务与关联公司共享必要信息；为提供如配送、第三方支付等服务与有关合作商、
      第三方商家、支付机构等共享有关的必要信息等。我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>我们会通过应用程序接口（</font>API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方SDK会有所不同，
      一般包括第三方账号登录类、分享类、第三方支付类、地图导航类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。
		</span>
	</p>
	<p style="text-align: justify;">
		<b>
			<span style="color: rgb(51, 51, 51);">如我们基于前述目的共享未成年人的个人信息，我们将就此事项的</span>
		</b>
		<b>合法、正当、必要性、对您孩子的权益的影响、我们采取的保护措施是否充分等进行评估。</b>
	</p>
	<p style="text-align: justify;">
		<b>
			<br>
		</b>
	</p>
	<p style="text-align: justify;">
		<b>
			<br>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">（二）转让</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、重组、资产转让或类似的交易，如涉及个人信息的转让，
        我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则</font>,我们将要求该公司、组织重新征求您的授权同意。
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">（三）公开披露</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，
      才可能公开披露您的个人信息：</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，
      我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，
      我们会要求必须出具与之相应的法律文件，如传票或调查函。</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">请注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或您孩子的个人信息甚至个人敏感信息，
        如您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">以下情形中，共享、转让、
      公开披露未成年人的个人信息无需事先征得未成年人及其监护人的授权同意：</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">1、与国家安全、国防安全有关的；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">2、与公共安全、公共卫生、重大公共利益有关的；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">3、与犯罪侦查、起诉、审判和判决执行等有关的；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">4、出于维护未成年信息主体或其他个人的生命、
      财产等重大合法权益但又很难得到监护人同意的；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">5、其他维护公共利益的情形，例如您的信用评价信息需要被公开/共享；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">6、未成年人或其监护人自行向社会公众公开的个人信息；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">7、从合法公开披露的信息中收集未成年人的个人信息的，
      如合法的新闻报道、政府信息公开等渠道。但是未成年人或其监护人明确拒绝或者处理该信息侵害其重大利益的除外。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，
      且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向得未成年人及其监护人通知并征得同意。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">五、我们如何保护和储存未成年人的个人信息</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">（一）个人信息的保存</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">1、保存期限：我们只会在达成本政策所述目的所需的期限内保留您的个人信息，
        除非法律法规有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。
        我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：完成与您相关的交易目的、维护相应交易及业务记录，
        以应对您可能的查询或投诉；保证我们为您提供服务的安全和质量；您是否同意更长的留存期间；是否存在关于保留期限的其他特别约定或法律法规规定。</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，
        或使其匿名化处理。在您主动注销账号时，我们将根据法律法规的要求进行数据处理。</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">2、保存地域：</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">我们在</span>
		<b>
			<span style="color: rgb(51, 51, 51);">中华人民共和国境内</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">收集和产生的个人信息，将存储在中国境内，但以下情形除外：</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>（</font>1）法律法规有明确规定的；
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>（</font>2）单独征得未成年人及其监护人的授权同意；
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">在上述情形中，我们会根据法律法规要求履行相应流程和义务，
      并要求数据接收方按照不低于本隐私政策以及其他相关的安全保密措施来处理个人信息。</span>
	</p>
	<p style="text-align: justify;">
		<b>
			<span style="color: rgb(51, 51, 51);">3、终止运营：</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<font>如果发生终止运营等情形，我们将会至少提前</font>30天通知您及您的孩子，并在终止运营后对未成年人的个人信息进行删除或匿名化处理
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">（二）个人信息的保护措施</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<br>
			</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>（</font>1）数据安全措施
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">为更加严格的保障未成年人的个人信息安全，我们将按照《隐私政策》
      保存及保护未成年人的个人信息，同时建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范未成年人个人信息的存储和使用，
      并将未成年人个人信息列为最高敏感级别信息进行保护，通过与信息接触者保密协议、权限管理、最小化访问控制和安全审计等机制来对数据进行全面安全控制。
      防止未成年人的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>（</font>2）安全认证
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">我们与监管机构、第三方测评机构建立了良好的协调沟通机制，
      及时抵御并处置各类信息安全威胁，为未成年人的信息安全提供全方位保障。</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>（</font>3）数据安全事件应急预案
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，
        协助我们保证您的孩子的账号安全。如您发现您的孩子的个人信息泄密，尤其是账号或密码发生泄露，
        请您或您的孩子立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。</font>
			&nbsp;
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>（</font>4）安全事件
			</span>
		</b>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<font>在不幸发生未成年人个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知</font>
      :安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您或您的孩子可自主防范和降低风险的建议、对您或您的孩子的补救措施等。
      我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您及您的孩子，难以逐一告知个人信息主体时，
      我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报未成年人个人信息安全事件的处置情况。
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<br>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="color: rgb(51, 51, 51); font-size: 10.5pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
				<font>六、</font>
				<font>如何管理未成人的个人信息</font>
			</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<font>为了方便您或您的孩子在使用我们的产品及服务期间可以更好访问和管理您的孩子的个人信息，您和您的孩子享有以下权利</font>:
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
      （一）访问、更正、删除未成年人的个人信息，法律法规规定的例外情况除外。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">（二）改变授权同意的范围或撤回授权</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">（三）注销账号</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">（四）自主管理控制营销信息及定向推送</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
      我们已经在《隐私政策》中对上述操作设置进行了详细的介绍，您和您的孩子可以按照《隐私政策》中的</span>
		<b>
			<span style="color: rgb(51, 51, 51);">“您如何管理您的信息”</span>
		</b>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">章节指引进行操作。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">七、通知和修订</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">为给您和您的孩子提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。
      未经您和您的孩子明确同意，我们不会削减您和您的孩子依据本隐私政策所应享有的权利。我们会通过在我们网站、
      移动端上发出更新版本或以其他方式提醒您和您的孩子相关内容的更新，也请您陪同和指导您的孩子访问我们以便及时了解最新的隐私政策。
      在前述情况下，若您和您的孩子继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">八、如何联系我们</span>
		</b>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">您或您的孩子可以通过</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">{{appName}}</span>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
      App上提供的在线联系方式与我们联系，我们将在15天内答复您及您的孩子的请求；</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<span style="color: rgb(51, 51, 51); font-size: 12pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">附录：</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
        1、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
        本隐私政策中可能涉及到的个人信息包括但不限于：个人基本信息（包括姓名、电话号码、性别、住址、生日等）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；
        网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（包括银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；
        个人健康生理信息（包括病症、病历、病史等）；联系人信息（包括通讯录信息、好友列表等）；个人上网记录（包括网站浏览记录、点击记录等）；
        个人设备信息（包括唯一设备识别码等描述个人常用设备基本情况的信息）；个人位置信息（包括行踪轨迹、精准定位信息、住宿信息等）。</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
        2、个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。
        本隐私政策中可能涉及到的个人敏感信息包括：个人身份认证信息（包括身份证、军官证、护照、驾驶证、学生证等）；
        个人财产信息（银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；其他信息（行踪轨迹、通讯录信息、住宿信息、精准定位信息等）；14周岁以下的未成年人信息。</span>
		</b>
	</p>
	<p>
		<b>
			<span style="color: rgb(51, 51, 51);">
        3、未成年人个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定未成年人身份或者反映未成年人活动情况的各种信息。
        例如：账号登录名和密码、电话号码、身份证件信息、银行卡信息、交易信息及支付验证信息（如静态或动态验证码）等。</span>
		</b>
	</p>
</div>

</template>
<script>
export default {
  name: 'Pguide',
  mounted() {
    document.title = '未成年人隐私政策';
  },
  computed: {
    appName() {
      return this.$route.query?.appName || '有鱼记账';
    },
    appType() {
      return this.$route.query?.appType || 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding: 20px;
}
.title {
  font-size: 30px;
  padding: 80px 0;
}
</style>
